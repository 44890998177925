import React from 'react';
import { withRouter } from "react-router";

class Secret extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isReady: false,
			secret: null,
			error: null
		};
        this.copyClip = this.copyClip.bind(this);
	}

	componentDidMount() { 
		const id = this.props.match.params.id;
		fetch(process.env.REACT_APP_API_URL+"/"+id)
		.then(res => res.json())
		.then(
			(result) => {
				this.setState({ secret: result.secret }) 
				this.setState({ isReady: true }) 
			},
			(error) => { 
				this.setState({ error }) 
				this.setState({ isReady: true }) 
				console.log("ERROR"+error)
			}
      	)
	};

    copyClip = () => {
      console.log("Test")
	  this.secretField.select()
    }

	render() {
		if (this.state.isReady === false) {
			return <div className="secret"><h1>Retreiving secret...</h1></div>
		} else if (this.state.error == null) {
			return <div className="secret"><h1>Your secret is:</h1><input ref={(input) => { this.secretField = input; }} onClick={this.copyClip} value={this.state.secret} readOnly/></div>;
		} else if (this.state.error != null) {
			return <div className="secret"><h1>Not found</h1></div>;
		}
  }


}

export default withRouter(Secret);
