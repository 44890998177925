import React from 'react';

class Footer extends React.Component {

	componentDidMount() {
		document.title = "SimpleSecret"
	}

	render() {
		return (
			<div className="footer">
				<div><a href="https://gitlab.com/awthomson/com-cloudy76-simplesecret">SimpleSecret Source Code</a></div>
				<div>Copyright © 2021 - cloudy76.com</div>
				<div><a href={process.env.REACT_APP_URL}>Home</a></div>
			</div>
		);
	}

}

export default Footer;
