import React from 'react';

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
			secret: null,
			expire: "1d",
   	    	isSubmitted: false,
   	    	URL: "Generating URL...",
			error: null
        };
		this.updateInputSecret = this.updateInputSecret.bind(this);
		this.updateInputExpire = this.updateInputExpire.bind(this);
        this.copyClip = this.copyClip.bind(this);

    }

	randomSecret = () => {
		var pwdA="ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz"
		var pwd1="23456789"
		var pwdS="~!@#$%^&*()_+-={}[],.<>"
		var pwdChars = pwdA + pwdA + pwdA + pwdA + pwd1 + pwd1 + pwd1 + pwdS + pwdS;
		var pwdLen = Math.floor(Math.random()*6)+14;
		var randPassword = Array(pwdLen).fill(pwdChars).map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join('');
		this.setState({secret : randPassword})
	}

	createSecret = () => {
		this.setState({ isSubmitted: true })

	    	const requestOptions = {
       	 		method: 'POST',
       		 	headers: { 'Content-Type': 'application/json' },
       		 	body: JSON.stringify({ secret: this.state.secret, expire: this.state.expire })
    		};
    		fetch(process.env.REACT_APP_API_URL, requestOptions)
    		.then(res => res.json())
    		.then(
        		(result) => {
            		this.setState({ isSubmitted: true })
            		this.setState({ URL: process.env.REACT_APP_URL+"/"+result.uid })
        	},
        	(error) => {
            		this.setState({ error })
            		this.setState({ isSubmitted: true })
            		console.log("ERROR"+error)
        	}
    		)
	}


	updateInputSecret(event) {
		this.setState({secret : event.target.value})
	}

	updateInputExpire(event) {
		this.setState({expire: event.target.value})
	}

    copyClip = () => {
		this.secretURL.select();
		document.execCommand("copy");
    }


	render() {
		if (this.state.isSubmitted === false) {
			return (
				<div className="form">
					<div class="formTitle">SimpleSecret</div>
					<h1>Create a secret</h1>

					<hr/>
					<p>Share passwords and secrets securely over the internet by a unique URL that will be available only once.</p>
					<p>Enter your secret in the field below, or generate a random password.</p>

					<label>Secret:</label>
					<input onChange={this.updateInputSecret} value={this.state.secret}></input>
					<br/>

					<label>Expiry time:</label>
					<select name="expire" id="expire" onChange={this.updateInputExpire}>
						<option value="1h">1 hour</option>
						<option value="1d">1 day</option>
						<option value="1w">1 week</option>
						<option value="1m">1 month</option>
					</select>

					<div class="buttonBar">
					<button onClick={this.randomSecret} class="secondary">Generate</button>
					<button onClick={this.createSecret} class="action">Create</button>
					</div>

				</div>
			);
		} else {
			if (this.state.error == null) {
				return (
					<div className="submitted">
						<h1>Submitted</h1>
						<input ref={(URL) => { this.secretURL= URL; }} onClick={this.copyClip} value={this.state.URL} readOnly></input>
					</div>
				);
			} else {
				return (
					<div>
						<h1>ERROR</h1>
					</div>
				);
			}
		}
	}

}

export default Home;
