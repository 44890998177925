import './App.css';
import Footer from './Footer';
import Secret from './Secret';
import Home from './Home';
import React from "react";

import {
 BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

export default function App() {

  return (
    <Router>
      <div>
        <Switch>
          <Route path="/:id">
            <Secret />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
		<Footer/>
      </div>
    </Router>
  );
}

